








import SfLink from '/storefrontUI/components/components/atoms/SfLink/SfLink.vue';
import SfIcon from '/storefrontUI/components/components/atoms/SfIcon/SfIcon.vue';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HeaderNavigationItem',
  components: {
    SfLink,
    SfIcon,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: '',
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
  },
});
