







































import SfHeader from '/storefrontUI/components/components/organisms/SfHeader/SfHeader.vue';
import SfOverlay from '/storefrontUI/components/components/atoms/SfOverlay/SfOverlay.vue';

import {
  ref,
  defineComponent,
  useRoute, onMounted
} from '@nuxtjs/composition-api';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import {
  useUiHelpers,
} from '~/composables';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';
import HeaderLogo from '~/components/HeaderLogo.vue';

export default defineComponent({
  components: {
    HeaderNavigation,
    SfHeader,
    SfOverlay,
    HeaderLogo,
    SearchResults: () => import('~/components/Header/SearchBar/SearchResults.vue'),
    SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
  },
  setup() {
    const route = useRoute();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { categories: categoryList, load: categoriesListLoad } = useCategory();

    const isSearchOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);

    const categoryTree = ref<CategoryTree[]>([]);
    const categoryTreeWomen = ref<CategoryTree[]>([]);
    const categoryTreeMen = ref<CategoryTree[]>([]);


    onMounted(async () => {
      await categoriesListLoad({ pageSize: 20 });

      categoryTree.value = categoryList.value?.[0]?.children
        .filter((category) => category.include_in_menu);

      categoryTreeWomen.value = categoryList.value?.[0]?.children
        .filter((category) => category.include_in_menu)
        .filter((category) => category.name == 'Donna' || category.name == 'Women');
      categoryTreeWomen.value = categoryTreeWomen.value?.[0]?.children;

      categoryTreeMen.value = categoryList.value?.[0]?.children
        .filter((category) => category.include_in_menu)
        .filter((category) => category.name == 'Uomo' || category.name == 'Men');
      categoryTreeMen.value = categoryTreeMen.value?.[0]?.children;
    });

    return {
      categoryTree,
      categoryTreeWomen,
      categoryTreeMen,
      getCatLink,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      route,
    };
  },
});
